import Link from 'next/link'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { GenericButton } from '../components/common/button'
import RightArrow from '../components/common/svg/common/RightArrow'
import NotFoundGraphic from '../components/common/svg/non-found/NotFoundGraphic'
import NotFoundGraphicMobile from '../components/common/svg/non-found/NotFoundGraphicMobile'
import SizedBox from '../components/common/ui/SizedBox'
import TiltedBar from '../components/common/ui/TiltedBar'
import MainWrapper from '../components/layouts/Layout'
import A7AMeta from '../components/ssr/A7AMeta'
import { A7AColumn, A7ARow, SectionHeroWrapper } from '../utils/Container'
import { useMediaQuery } from '../utils/useMediaQuery'
import { getServerTranslations } from '../lib/i18n/getServerTranslations'

const NotFoundWrapper = styled.div`
  margin: 0 auto;
  max-width: 926px;
`

const NotFoundContainer = styled.div`
  margin: 30px;
  ${(props) => props.theme.mediaQuery.desktop} {
    margin: 60px 0px;
  }
`

const NotFoundHero = styled(SectionHeroWrapper)`
  background: url('/images/ArticlesBackground.webp');
  min-height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) => props.theme.mediaQuery.tablet} {
    height: 170px;
  }
`

const NotFoundBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 0 20px;
  margin: 100px 0;
  ${(props) => props.theme.mediaQuery.tablet} {
    grid-template-columns: 1fr 384px;
  }
`

const NotFoundLeft = styled.div`
  order: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props) => props.theme.mediaQuery.tablet} {
    order: 1;
    align-items: flex-start;
  }
  .not-found-description {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin-top: 28px;
    text-align: center;
    color: ${(props) => props.theme.colors.textColors[0]};
    ${(props) => props.theme.mediaQuery.tablet} {
      text-align: left;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const NotFoundRight = styled.div`
  order: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  ${(props) => props.theme.mediaQuery.tablet} {
    order: 2;
  }
`

const NotFoundHeading = styled.h1`
  margin: 0px;
  font-weight: 500;
  color: white;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.textColors[0]};
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

const NotFound = () => {
  const isMobile = useMediaQuery('(max-width: 500px)')
  return (
    <>
      <A7AMeta
        tabTitle="Uh oh! 404 Error!"
        url="https://a7alabs.com"
        title="Uh oh! 404 Error!"
        description=""
        image="https://a7alabs.com/images/A7AThumbnail.webp"
      />
      <NotFoundHero isDarkTheme={1} />
      <NotFoundWrapper>
        <NotFoundContainer>
          <NotFoundBody>
            <NotFoundLeft>
              <div className="heading">
                <TiltedBar />
                <SizedBox height={0} width={20} />
                <NotFoundHeading>OOPS! 404 Error!</NotFoundHeading>
              </div>
              <p className="not-found-description">
                It looks like you’re reached a URL that doesn’t exist. Please
                use the navigation above or home btton below to find your way
                back to our website!
              </p>
              <SizedBox height={28} width={0} />

              <Link href="/">
                <GenericButton
                  text={'Go to Home'}
                  height={50}
                  width={196}
                  isFullWidth={false}
                  isDisabled={false}
                  isProcessing={false}
                  onClick={() => {}}
                >
                  <SizedBox width={18} height={0} />
                  <RightArrow />
                </GenericButton>
              </Link>
            </NotFoundLeft>
            <NotFoundRight>
              {isMobile ? <NotFoundGraphicMobile /> : <NotFoundGraphic />}
            </NotFoundRight>
          </NotFoundBody>
        </NotFoundContainer>
      </NotFoundWrapper>
    </>
  )
}

export default NotFound

NotFound.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <MainWrapper>
        <main>{page}</main>
      </MainWrapper>
    </>
  )
}

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await getServerTranslations(locale, ['common'])),
      // Will be passed to the page component as props
    },
  }

}